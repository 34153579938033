import React, { useState, useEffect, useMemo } from "react"
import Img from "gatsby-image"
import { navigate } from "gatsby"

import {
  useCartItems,
  useCartTotals,
  useRemoveItemFromCart,
  useQuantityChange,
  useCheckout,
  useAddItemToCart,
} from "../../../context/StoreContext"

const Cart = ({ data }) => {
  const checkout = useCheckout()
  const lineItems = useCartItems()
  const products = data.allShopifyProduct.nodes
  const variants = data.allShopifyProductVariant.nodes
  const { tax, total } = useCartTotals()
  const removeFromCart = useRemoveItemFromCart()
  const addItemToCart = useAddItemToCart()
  const handleQuantityChange = useQuantityChange()

  const betterProductHandles = products.map(({ handle, variants }) => {
    const newVariants = variants.map(variant => variant.shopifyId)
    return {
      variants: newVariants,
      handle,
    }
  })

  
  function getImageFixedForVariant(variantId) {
    const selectedVariant = variants.find(variant => {
      return variant.shopifyId === variantId
    })

    if (selectedVariant) {
      return selectedVariant.image.localFile.childImageSharp.fixed
    }

    return null
  }

  function handleContinueSupportClick() {
    navigate("/support")
  }




  const LineItem = ({ item }) =>{
    const [quantity, setQuantity] = useState(item.quantity);

    function handleQuantity(value, currentQuantity, itemId) {
      if (!value) {
        setQuantity("")
      }

      if (value) {
        setQuantity(value)
        handleQuantityChange(itemId, value)
      }
    }
    return (
    <div class="row" style={{ marginTop: "20px" }}>
      <div class="col-4 align-self-center">
        <Img fixed={getImageFixedForVariant(item.variant.id)} />
      </div>
      <div class="col align-self-center">
        <p class="font-weight-bold">{item.title}</p>
        {item.variant.selectedOptions.map(({ name, value }) => { 
          if (name != "Title") {
            return (
              <p class="mb-3 text-muted text-uppercase small">
               {name}:{value}
             </p>
            )
          } 
        })}
        <p class="font-weight-bold">
          <span>
            <strong>€{item.variant.price}</strong>
          </span>
        </p>
      </div>
      <div class="col-3 align-self-center">
        <div class="d-grid gap-2">
          <div class="p-2">
            <input
              style={{ width: "50px" }}
              type="number"
              inputMode="numeric"
              id={item.id}
              onChange={event =>
                handleQuantity(
                  event.target.value,
                  item.quantity,
                  item.id
                )}
              step="1"
              value={quantity}
            />
          </div>
          <div class="col">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-cart-x-fill"
              viewBox="0 0 16 16"
              onClick={() => removeFromCart(item.id)}
            >
              <path
                fillRule="evenodd"
                d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM4 14a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm7 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.354 5.646a.5.5 0 1 0-.708.708L7.793 7.5 6.646 8.646a.5.5 0 1 0 .708.708L8.5 8.207l1.146 1.147a.5.5 0 0 0 .708-.708L9.207 7.5l1.147-1.146a.5.5 0 0 0-.708-.708L8.5 6.793 7.354 5.646z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    )
  }  

  return (
    <div class="container">
      <h3>Cart</h3>
      <hr class="mt-3 mb-3" />
      <div class="row">
        <div class="col-md">
          {lineItems.map(item => (
            <LineItem key={item.id} item={item} />
          ))}
        </div>
        <div class="col-md-4">
          <div class="d-flex">
            <div class="p-2 flex-fill">
              <p class="mb3 medium">Subtotal</p>
            </div>
            <div class="p-2">
              <p class="font-weight-bold">{total}</p>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-lg btn-block"
            style={{background: "#FFDA00", color: "#000000", borderColor: "#000000", borderWidth: "5px", fontFamily: "Karate"}}
            onClick={checkout}
          >
            C H E C K O U T
          </button>
          <button
            type="button"
            class="btn btn-lg btn-block "
            style={{background: "#FFDA00", color: "#FF00E7", borderColor: "#FF00E7", borderWidth: "5px", fontFamily: "Karate"}}
            onClick={handleContinueSupportClick}
          >
            CONTINUE SUPPORT &#128420;
          </button>
        </div>
      </div>
    </div>
  )
}

export default Cart
