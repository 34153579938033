import React from 'react'
import { graphql } from "gatsby"
import Header from 'components/Header'
import Cart from 'components/Shop/Cart'
import KarateFont from "../fonts/fonts"

const CartPage = ({data}) => {
    return (
      <div>
        <KarateFont/>
        <Header/>
        <Cart data={data}/>
      </div>
    )
}

export default CartPage;

export const cartQuery = graphql`
query useCartQuery {
    allShopifyProductVariant {
        nodes {
          shopifyId
          image {
            localFile {
              childImageSharp {
                fixed(height: 100, width: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
      allShopifyProduct {
        nodes {
          handle
          variants {
            shopifyId
          }
        }
      }
    }
`